import React , {useState , useEffect} from 'react'
import { Table , Input, Spin} from 'antd';
import './carbidlist.scss'
import {GoUnverified} from 'react-icons/go'
import {FiSearch} from 'react-icons/fi'
import {RiEye2Line , RiDeleteBin2Fill , RiEdit2Fill , RiSendPlaneFill} from 'react-icons/ri'
import moment from 'moment'
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { fetchUnverifiedCarBids, verifyCarProduct , deleteCarProduct } from '../stores/api_calls/carlists';
import CustomConfirmModal from '../custom_components/customeConfirmModal';
import { IconButton } from '../custom_components/customButton';
import CustomProductView from '../custom_components/customProductView';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Kjur from '../stores/utils/jwt';
import { error, success } from '../contants/snackbars';
import CustomSMSModal from '../custom_components/customSMSModal';
import { sendSMS } from '../stores/api_calls/upload';

const UnverifiedCarBidLists = () => {

    const navigate = useNavigate()

    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState([])
    const [keywordSearch , setKeywordSearch] = useState("")
    const [showModal , setShowModal] = useState({showDelete:false , showVerify:false , showPreview:false , showSMS:false, data:{}})
    const [tablePagination , setTablePagination] = useState({
        current: 1,
        pageSize: 10,
        total:30,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })

    

    const columns = [
      {
        title: "Date Created",
        dataIndex: "dateCreated",
        render: (text, record) =>
          moment.utc(record.date_created).local().format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "Bike Name",
        dataIndex: "bikeName",
        // sorter: true,
        render: (text, record) => `${record.product_name}`,
      },
      {
        title: "Bike Plate Number",
        dataIndex: "bikePlateNumber",
        render: (text, record) => `${record.car_plate_number ?? "Unknown"}`,
      },
      {
        title: "Brand Model",
        dataIndex: "brand",
        render: (text, record) =>
          `${record.product_brand_model?.model_name ?? "Unknown"}`,
        // width: '20%',
      },
      {
        title: "Direct Seller",
        dataIndex: "directSeller",
        render: (text, record) =>
          `${record.advertisement_contact_details?.direct_seller == true ? record.advertisement_contact_details?.name : ''}`,
      },
      {
        title: "Advertiser / Dealer",
        dataIndex: "advertiser",
        render: (text, record) =>
          `${record.advertisement_contact_details?.direct_seller == false ? record.advertisement_contact_details?.name : ''}`,
      },

      {
        title: "Product Price",
        dataIndex: "price",
        render: (text, record) => `S$ ${record.product_price.toLocaleString()}`,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (text, record) => (
          <div className="actions-icons-container">
            <IconButton
              className="icon-button view"
              tootTipTitle="View Bike"
              Icon={<RiEye2Line />}
              onClick={() => onPreviewProduct(record)}
            />
            {/* <IconButton
              className="icon-button unverified"
              tootTipTitle="Verify Bike"
              Icon={<GoUnverified />}
              onClick={() => onVerify(record)}
            /> */}
            <IconButton
              className="icon-button verified "
              tootTipTitle="Send SMS"
              Icon={<RiSendPlaneFill />}
              onClick={() => onSend(record)}
            />
            <IconButton
              className="icon-button edit"
              tootTipTitle="Edit Bike"
              Icon={<RiEdit2Fill />}
              onClick={() => onEdit(record)}
            />
            <IconButton
              className="icon-button delete"
              tootTipTitle="Delete Bike"
              Icon={<RiDeleteBin2Fill />}
              onClick={() => onDelete(record)}
            />
          </div>
        ),
      },
    ];

    useEffect(()=>{
        refetchLists()
    },[])

    useEffect(()=>{
        if(tablePagination.current || tablePagination.pageSize){
            refetchLists()
        }
    },[tablePagination.current , tablePagination.pageSize])

    const refetchLists = () => {
        const pagination = {
            page:tablePagination.current,
            limit:tablePagination.pageSize,
            keyword:keywordSearch
        }

        fetchData(pagination)
    }

    const fetchData = (data) => {

        setIsLoading(true)

        const unverifiedCars = fetchUnverifiedCarBids({...data, productCondition: "Used"})

        unverifiedCars.then((res)=>{
            if(res.data.success){
                console.log(res.data.data);
                setTableData(res.data.data)
                setTablePagination({
                    ...tablePagination,
                    total:res.data.meta.pagination.total
                })
                setIsLoading(false)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
        
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            const pagination = {
                page : 1,
                limit : 10,
                keyword:keywordSearch
            }
            fetchData(pagination)
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTablePagination(pagination)
    };

    const onEdit = (record) => {
        console.log(record);
        navigate(
          {
            pathname: "../bike-bids/edit",
            search: `?${createSearchParams({ id: record._id })}`,
          },
          { replace: true }
        );
    }

    const onPreviewProduct = (record) => {
        setShowModal({...showModal , showPreview:true , data:record})
    }

    const onDelete = (record) => {
        setShowModal({...showModal , showDelete:true , data:record})
    }

    const onVerify = (record) => {
        setShowModal({...showModal , showVerify:true , data:record})
    }

    const onDeleteSubmit = () => {
        const filteredData = tableData.filter(el=>el._id!==showModal.data._id)

        const onDelete = deleteCarProduct(showModal.data._id)
        onDelete.then((res)=>{
            if(res.data.success){
                setTableData(filteredData)
                setShowModal({...showModal , showDelete:false , data:{}})
                success("Bike deleted successfully.")
            }
        }).catch((e)=>{
            error(`${e.response.data.server_response}`)
        })
    }

    const onVerifySubmit = () => {
        
        
        const form = {
            message:`Your bike ${showModal.data.product_name} - with plate number ${showModal.data.car_plate_number} is already verified`,
            numberArray:[showModal.data.advertisement_contact_details?.user_contact_numbers.number]
        }
        // console.log(form)
        const onVerify = verifyCarProduct(showModal.data._id)
        onVerify.then((res)=>{
            if(res.data.success){
                const filteredData = tableData.filter(el=>el._id!==showModal.data._id)
                success("Bike was verified successfully.")
                setTableData(filteredData)
                setShowModal({...showModal , showVerify:false , data:{}})

                const sendMessage = sendSMS(form)
                sendMessage.then((res)=>{
                    if(res.data.success){
                        success(`Message sent to ${form.numberArray[0]}`)
                    }
                }).catch((e)=>{
                    console.log("Send SMS Failed")
                })
                
            }
        }).catch((e)=>{
            error(`${e.response.data.server_response}`)
        })
    }

    const onSend = (record) => {
        setShowModal({...showModal , showSMS:true , data:record})
    }

    return (
        <div className='table-container'>
            <div className='table-title-icon unverified'>
                <GoUnverified/>
            </div>
            <p className='table-title-text unverified'>Unverified Bike Bidding Lists</p>

            <div
                className='search-container'
            >
                <Input
                    size='large'
                    style={{width:300}}
                    placeholder='Search Record'
                    prefix={<FiSearch/>}
                    onChange={e=>setKeywordSearch(e.target.value)}
                    onKeyPress={onEnter}
                />
            </div>

            <div className='scrollable-table-container'>
                <Table
                    columns={columns}
                    rowKey={record => record._id}
                    dataSource={tableData}
                    pagination={tablePagination}
                    loading={{indicator:<Spin size='large'/> , spinning:isLoading}}
                    onChange={handleTableChange}
                    bordered
                    sticky
                />
            </div>
            
            <CustomDeleteModal
                title="Delete Bike Ad"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.product_name}` , type:"car ad"}}
            />

            <CustomConfirmModal
                title="Verify this Bike Ad"
                visible={showModal.showVerify}
                onSubmit={onVerifySubmit}
                onCancel={()=>setShowModal({...showModal , showVerify:false , data:{}})}
                data={{itemName:`${showModal.data.product_name}` , type:"car ad"}}
            >
                <p className='modal-phrase'>Are you sure you want to verify <span className='name'>"{showModal.data.product_name}"</span>. By clicking <span>"Okay"</span> , this car will be listed as verify and can be seen at verified car lists page.</p>
            </CustomConfirmModal>

            <CustomProductView
                title="Product View"
                visible={showModal.showPreview}
                data={showModal.data}
                onCancel={()=>setShowModal({...showModal , showPreview:false , data:{}})}
            />

            <CustomSMSModal
                visible={showModal.showSMS}
                onCancel={()=>setShowModal({...showModal , showSMS:false , data:{}})}
                data={showModal.data}
            />

        </div>
    )
}

export default UnverifiedCarBidLists;
