import React, { useState, useContext, useRef, useEffect } from "react";
import { Button, Col, Row } from "antd";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import "./App.css";
// import NavigationIndex from "./components/navigation/NavigationIndex";
import LeftSideMenu from "./components/navigation/LeftSideMenu";
import DashboardIndex from "./components/dashboard/DashboardIndex";
import { UserContext } from "./components/stores/contexts/userContext";
import Login from "./components/auth/Login";
import UserChips from "./components/auth/UserChips";
import UnverifiedCarLists from "./components/lists_cars/UnverifiedCarLists";
import VerifiedCarLists from "./components/lists_cars/VerifiedCarLists";
import CarFormIndex from "./components/forms/cars/CarFormIndex";
import SoldCarLists from "./components/lists_cars/SoldCarLists";
import BannerLists from "./components/lists_banners/BannerIndex";
import AdLists from "./components/lists_ads/AdLists";
import LandingAdLists from "./components/lists_ads/LandingAdLists";
import ProductAdLists from "./components/lists_ads/ProductAdLists";
import OtherAdLists from "./components/lists_ads/OtherAdLists";
import UserLists from "./components/lists_users/UserIndex";
import DealerLists from "./components/lists_dealers/DealerIndex";
import PackagesIndex from "./components/lists_packages/PackagesIndex";
import BrandIndex from "./components/lists_brands/BrandIndex";
import ArticleIndex from "./components/lists_articles/ArticleIndex";
import ArticleForm from "./components/forms/articles/ArticleForm";
import ForgotPassword from "./components/auth/ForgotPassword";
import {
  NoAccessPage,
  NotFoundPage,
} from "./components/splashpages/SplashPages";
import Profile from "./components/profile/Profile";
import CarRentalIndex from "./components/lists_car_rental/CarRentalIndex";
import VerifiedRentalLists from "./components/lists_car_rental/VerifiedRentalLists";
import UnverifiedRentalLists from "./components/lists_car_rental/UnverifiedRentalLists";
import ProductRental from "./components/forms/rentals/ProductRental";
import Trigger from "./components/trigger_page/Trigger";
// import IdleTimer from "react-idle-timer";
// import Countdown from "react-countdown";
// import CountDownModal from "./components/custom_components/countdownModal";
import CareersIndex from "./components/careers/CareersIndex";
import CareerForm from "./components/forms/careers/CareerForm";
import PlateNumberList from "./components/lists_plate_numbers/PlateNumbersIndex";
import CoeIndex from "./components/coe/CoeIndex";
import FaqIndex from "./components/faq/FaqIndex";
import AboutIndex from "./components/about/AboutIndex";
import CoeForm from "./components/forms/coe_v2/CoeForm";
import BankIndex from "./components/lists_banks/BankIndex";
import InsuranceIndex from "./components/lists_insurances/InsuranceIndex";
import CarWorkShops from "./components/car-workshops/CarWorkshopsIndex";
import CarWorkshopForm from "./components/forms/car-workshops/CarWorkshopForm";
import Announcement from "./components/announcement/announcement";
import AnnounceForm from "./components/forms/announcement/AnnounceForm";
import UnverifiedNewCarLists from "./components/lists_cars/UnverifiedNewCarLists";
import VerifiedNewCarLists from "./components/lists_cars/VerifiedNewCarLists";
import NewCarFormIndex from "./components/forms/cars/NewCarFormIndex";

import VerifiedCarBidLists from "./components/lists_car_bids/VerifiedCarBidLists";
import UnverifiedCarBidLists from "./components/lists_car_bids/UnverifiedCarBidLists";
import CarBidFormIndex from "./components/forms/car-bids/CarBidFormIndex";
import SoldCarBidLists from "./components/lists_car_bids/SoldCarBidLists";
import BiddingDealerLists from "./components/lists_bidding_dealers/BiddingDealerIndex";
import CommentLists from "./components/lists_bidding_comments/CommentLists";
import UnverifiedCommentLists from "./components/lists_bidding_comments/UnverifiedCommentLists";
import BiddingPriceList from "./components/lists_bidding_comments/BiddingPriceList";
import UnverifiedBiddingPriceList from "./components/lists_bidding_comments/UnverifiedBiddingPriceList";
import MarketingEmailList from "./components/lists_marketing_emails/MarketingEmailIndex";
import UnverifiedEmailList from "./components/lists_marketing_emails/UnverifiedEmailIndex";
import VerifiedCommercialLists from "./components/lists_cars/VerifiedCommercialLists";
import UnverifiedCommercialLists from "./components/lists_cars/UnverifiedCommercialLists";
import DraftCarLists from "./components/lists_cars/DraftCarLists";
// import AccessoriesList from "./components/lists_accessories/AccessoriesIndex";
import VerifiedAccessoriesList from "./components/lists_accessories/VerifiedAccessoriesList";
import UnverifiedAccessoriesList from "./components/lists_accessories/UnverifiedAccessoriesList";
import DraftAccessoriesList from "./components/lists_accessories/DraftAccessoriesList";
import SoldAccessoriesList from "./components/lists_accessories/SoldAccessoriesList";

import AccessoryForm from "./components/forms/accessories/AccessoryForm";
import UnverifiedBicycleList from "./components/lists_bicycles/UnverifiedBicycleList";
import BicycleFormIndex from "./components/forms/bicycles/BicycleFormIndex";
import VerifiedBicycleList from "./components/lists_bicycles/VerifiedBicycleList";
import SoldBicycleList from "./components/lists_bicycles/SoldBicycleList";
import DraftBicycleList from "./components/lists_bicycles/DraftBicycleList";

function App() {
  let navigate = useNavigate();

  const [userConfig, setUserConfig] = useContext(UserContext);
  const [startTimer, setStartTimer] = useState(false);
  const idleTimer = useRef(null);

  // const onUserIdle = () => {
  //   setStartTimer(true);
  //   console.log("user id idle");
  // };

  // const onUserActive = () => {
  //   setStartTimer(false);
  //   console.log("user id is active");
  // };

  return (
    <div className="content-container">
      {userConfig.isLoggedIn ? (
        <div className="side-nav-container">
          {/* <IdleTimer
            ref={idleTimer}
            timeout={900000}
            onIdle={onUserIdle}
            onActive={onUserActive}
          />
          <CountDownModal
            startTimer={startTimer}
            setStartTimer={setStartTimer}
          /> */}
          <LeftSideMenu />
        </div>
      ) : null}

      {!userConfig.isLoggedIn ? (
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace={true} />} />
          <Route path="/login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route
            path="*"
            element={
              <div>
                NO ACCESS{" "}
                <Button onClick={() => navigate("../", { replace: true })}>
                  LOGIN
                </Button>
              </div>
            }
          />
        </Routes>
      ) : userConfig.isLoggedIn ? (
        <div className="main-container" style={{ paddingBottom: "20px" }}>
          {userConfig.isLoggedIn ? <UserChips /> : null}
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/dashboard" replace={true} />}
            />
            <Route
              path="/login"
              element={<Navigate to="/dashboard" replace={true} />}
            />

            <Route path="dashboard" element={<DashboardIndex />} />
            <Route path="profile" element={<Profile />} />

            <Route path="bikes/verified" element={<VerifiedCarLists />} />
            {/* <Route path="bikes/verified-commercial" element={<VerifiedCommercialLists />} /> */}
            <Route path="bikes/verified-new" element={<VerifiedNewCarLists />} />
            <Route path="bikes/unverified" element={<UnverifiedCarLists />} />
            {/* <Route path="bikes/unverified-commercial" element={<UnverifiedCommercialLists />} /> */}
            <Route path="bikes/unverified-new" element={<UnverifiedNewCarLists />} />
            <Route path="bikes/sold" element={<SoldCarLists />} />
            <Route path="bikes/draft" element={<DraftCarLists />} />

            <Route path="bikes/create" element={<CarFormIndex />} />
            <Route path="bikes/edit" element={<CarFormIndex />} />
            <Route path="bikes/create-new" element={<NewCarFormIndex />} />
            <Route path="bikes/edit-new" element={<NewCarFormIndex />} />

            <Route path="bike-bids/verified" element={<VerifiedCarBidLists />} />
            <Route path="bike-bids/unverified" element={<UnverifiedCarBidLists />} />
            <Route path="bike-bids/create" element={<CarBidFormIndex />} />
            <Route path="bike-bids/edit" element={<CarBidFormIndex />} />
            <Route path="bike-bids/sold" element={<SoldCarBidLists />} />

            {/* <Route path="rentals" element={<CarRentalIndex />} /> */}
            <Route path="rentals/verified" element={<VerifiedRentalLists />} />
            <Route
              path="rentals/unverified"
              element={<UnverifiedRentalLists />}
            />
            <Route path="/rentals/create" element={<ProductRental />} />
            <Route path="/rentals/edit" element={<ProductRental />} />

            <Route path="bicycles/unverified" element={<UnverifiedBicycleList />} />
            <Route path="bicycles/verified" element={<VerifiedBicycleList />} />
            <Route path="bicycles/sold" element={<SoldBicycleList />} />
            <Route path="bicycles/draft" element={<DraftBicycleList />} />
            <Route path="bicycles/create" element={<BicycleFormIndex />} />
            <Route path="bicycles/edit" element={<BicycleFormIndex />} />

            <Route path="/banners" element={<BannerLists />} />

            <Route path="/ads" element={<AdLists />} />
            {/* <Route path="/ads/landing-page" element={<LandingAdLists />} />
            <Route path="/ads/product-page" element={<ProductAdLists />} />
            <Route path="/ads/other-page" element={<OtherAdLists />} /> */}

            <Route path="/users" element={<UserLists />} />
            <Route path="/dealers" element={<DealerLists isDealer={true} />} />
            <Route path="/direct-sellers" element={<DealerLists />} />
            <Route path="/marketing-emails/verified" element={<MarketingEmailList />} />
            <Route path="/marketing-emails/unverified" element={<UnverifiedEmailList />} />

            <Route path="/bidding-dealers" element={<BiddingDealerLists isDealer={true} />} />
            <Route path="/bidding-direct-sellers" element={<BiddingDealerLists />} />

            <Route path="/bidding-comments/verified" element={<CommentLists />} />
            <Route path="/bidding-comments/unverified" element={<UnverifiedCommentLists />} />
            <Route path="/bidding-comments/verified-bid-price" element={<BiddingPriceList />} />
            <Route path="/bidding-comments/unverified-bid-price" element={<UnverifiedBiddingPriceList />} />
            
            <Route path="/packages" element={<PackagesIndex />} />
            <Route path="/brands" element={<BrandIndex />} />

            <Route path="/articles" element={<ArticleIndex />} />
            <Route path="/articles/create" element={<ArticleForm />} />
            <Route path="/articles/edit" element={<ArticleForm />} />

            <Route path="/trigger" element={<Trigger />} />

            <Route path="/careers" element={<CareersIndex />} />
            <Route path="/careers/create" element={<CareerForm />} />
            <Route path="/careers/edit" element={<CareerForm />} />

            <Route path="/plate-numbers" element={<PlateNumberList />} />

            <Route path="/banks" element={<BankIndex />} />
            <Route path="/insurances" element={<InsuranceIndex />} />

            <Route path="/coe" element={<CoeIndex />} />
            <Route path="/coe/create" element={<CoeForm />} />
            <Route path="/coe/edit" element={<CoeForm />} />

            <Route path="/faq" element={<FaqIndex />} />
            <Route path="/about" element={<AboutIndex />} />

            <Route path="bike-workshops" element={<CarWorkShops />} />
            <Route path="/bike-workshops/create" element={<CarWorkshopForm />} />

            <Route path="bike-accessories/verified" element={<VerifiedAccessoriesList />} />
            <Route path="bike-accessories/unverified" element={<UnverifiedAccessoriesList />} />
            <Route path="bike-accessories/sold" element={<SoldAccessoriesList />} />
            <Route path="bike-accessories/draft" element={<DraftAccessoriesList />} />
            <Route path="bike-accessories/create" element={<AccessoryForm />} />
            <Route path="bike-accessories/edit" element={<AccessoryForm />} />

            <Route path="/announcement" element={<Announcement />} />
            <Route path="/announcement/create" element={<AnnounceForm />} />
            <Route path="/announcement/edit" element={<AnnounceForm />} />

            <Route path="/no-access" element={<NoAccessPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      ) : null}
    </div>
  );
}

export default App;
